import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => {
  require('./contact.scss')

  return (
    <Layout className={'contact-page'}>
      <SEO title="Contact" />
      <div>
        <div className={'contact-header'}>
          <h1><Link to={'/'}>{'<'}</Link></h1>
          <h1>Contact Us</h1>
        </div>
        <p>If you have any issues, feedback, or just love to chat, reach out to: <a href="mailto:hi@natalcharts.app">hi@natalcharts.app</a></p>
      </div>
    </Layout>
  )
}

export default SecondPage
